import React, { forwardRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { readInlineData, useFragment, useRefetchableFragment } from "react-relay";
import { match } from "ts-pattern";
import { AddProjectToScenarioCard } from "@components/relay/add-project-to-scenario-card";
import { ProjectCard } from "@components/relay/project-card/project-card.component";
import { selectScenarioProjectFilters } from "@redux/ProjectViewSlice";
import { type projectsGridPartContent_ProjectInScenarioInlineFragment$key } from "@relay/projectsGridPartContent_ProjectInScenarioInlineFragment.graphql";
import { type projectsGridPartContent_Refetch } from "@relay/projectsGridPartContent_Refetch.graphql";
import { type projectsGridPartContent_ScenarioFragment$key } from "@relay/projectsGridPartContent_ScenarioFragment.graphql";
import { type projectsGridPartContent_ScenarioRefetchableFragment$key } from "@relay/projectsGridPartContent_ScenarioRefetchableFragment.graphql";
import { type Staffing } from "@relay/staffViewPart_Query.graphql";
import {
	PROJECT_IN_SCENARIO_INLINE_FRAGMENT,
	SCENARIO_FRAGMENT,
	SCENARIO_REFETCHABLE_FRAGMENT,
} from "./projects-grid-part-content.graphql";
import { applyFilter } from "./projects-grid-part-content.utils";

export const ProjectsGridContent = forwardRef(
	(
		{
			scenarioFragmentRef,
		}: {
			scenarioFragmentRef: projectsGridPartContent_ScenarioFragment$key;
		},
		ref,
	) => {
		const scenario = useFragment<projectsGridPartContent_ScenarioFragment$key>(
			SCENARIO_FRAGMENT,
			scenarioFragmentRef,
		);

		const [refetchableProjectsData, refetch] = useRefetchableFragment<
			projectsGridPartContent_Refetch,
			projectsGridPartContent_ScenarioRefetchableFragment$key
		>(SCENARIO_REFETCHABLE_FRAGMENT, scenario);
		const projects = useMemo(
			() =>
				refetchableProjectsData?.projects?.edges?.map((e) =>
					readInlineData<projectsGridPartContent_ProjectInScenarioInlineFragment$key>(
						PROJECT_IN_SCENARIO_INLINE_FRAGMENT,
						e?.node!,
					),
				) ?? [],

			[refetchableProjectsData],
		);

		const projectFilters = useSelector(selectScenarioProjectFilters);

		useEffect(() => {
			refetch({
				projectFilters: {
					name: applyFilter(projectFilters.filterByName),
					divisions: applyFilter(projectFilters.filterByDivisions),
					regions: applyFilter(projectFilters.filterByRegions),
					stages: applyFilter(
						projectFilters.filterByStage ? [projectFilters.filterByStage] : undefined,
					),
					inDateRange:
						projectFilters.filterByDateFrom || projectFilters.filterByDateTo
							? {
									from: projectFilters.filterByDateFrom,
									to: projectFilters.filterByDateTo,
							  }
							: undefined,
					executives: applyFilter(projectFilters.filterByExecutives),
					staffing: match(projectFilters.filterByStaffing)
						.returnType<Staffing | undefined>()
						.with("Fully staffed", () => "FullyStaffed")
						.with("Not Fully Staffed", () => "NotFullyStaffed")
						.otherwise(() => undefined),
				},
				personOnAssignmentFilters: {
					jobTitles: applyFilter(projectFilters.filterByAssignmentRoles),
					executives: applyFilter(projectFilters.filterByExecutives),
					ids: applyFilter(projectFilters.filterByStaff),
					skillFilters: applyFilter(projectFilters.filterBySkills),
				},
			});
		}, [projectFilters]);

		const sortedProjects = useMemo(
			() =>
				projects.sort((x, y) => {
					switch (projectFilters.sorting) {
						case "ByNameAsc":
							return x.project?.name?.localeCompare(y.project.name);
						case "ByNameDesc":
							return -x.project?.name?.localeCompare(y.project.name);
						case "ByStartDateAsc":
							return x.project?.startDate?.localeCompare(y.project.startDate);
						case "ByStartDateDesc":
							return -x.project?.startDate?.localeCompare(y.project.startDate);
						case "ByEndDateAsc":
							return x.project?.endDate?.localeCompare(y.project.endDate);
						case "ByEndDateDesc":
							return -x.project?.endDate?.localeCompare(y.project.endDate);
					}
					return 0;
				}),
			[projects, projectFilters.sorting],
		);
		return (
			<div
				className="flex flex-wrap mt-2 pt-3 overflow-y-scroll to-table-print"
				ref={ref as any}
			>
				{sortedProjects.map((p) => {
					return (
						<ProjectCard
							key={p.id}
							scenarioFragmentRef={scenario}
							projectFragmentRef={p}
						/>
					);
				})}
				<AddProjectToScenarioCard scenarioFragmentRef={scenario} />
			</div>
		);
	},
);
