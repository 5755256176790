/**
 * @generated SignedSource<<165c0d25af92d6b529be5f5b7d894630>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RosterList_ScenarioFragment$data = {
  readonly id: string;
  readonly utilization: {
    readonly " $fragmentSpreads": FragmentRefs<"personCard_ScenarioUtilizationFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CheckScenarioPermissions_ScenarioFragment" | "personCardDraggable_ScenarioFragment" | "personCard_ScenarioFragment">;
  readonly " $fragmentType": "RosterList_ScenarioFragment";
};
export type RosterList_ScenarioFragment$key = {
  readonly " $data"?: RosterList_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RosterList_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RosterList_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CheckScenarioPermissions_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personCardDraggable_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personCard_ScenarioFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScenarioUtilization",
      "kind": "LinkedField",
      "name": "utilization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "personCard_ScenarioUtilizationFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "812c060d7fde31d9bcd1d851899f9fff";

export default node;
