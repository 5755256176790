/**
 * @generated SignedSource<<24531e01a3e562c9f4a919e92229f6d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AllocationGroupType = "assignmentRole" | "project" | "ungrouped";
export type IntervalType = "Days" | "Months" | "Quarters" | "Weeks";
import { FragmentRefs } from "relay-runtime";
export type staffViewPart_ScenarioFragment$data = {
  readonly id: string;
  readonly staffView: {
    readonly allocationGroups: ReadonlyArray<{
      readonly allocations: ReadonlyArray<{
        readonly gapDays: number;
        readonly lanes: ReadonlyArray<{
          readonly allocations: ReadonlyArray<{
            readonly assignment: {
              readonly id: string;
              readonly person: {
                readonly id: string;
                readonly name: string;
              } | null;
            } | null;
            readonly endDate: any | null;
            readonly id: string;
            readonly startDate: any | null;
            readonly " $fragmentSpreads": FragmentRefs<"allocationBarProvider_AllocationFragment">;
          }>;
        }>;
        readonly person: {
          readonly assignmentRole: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly id: string;
          readonly name: string;
          readonly sumOfProjectVolume: number | null;
          readonly " $fragmentSpreads": FragmentRefs<"personCard_PersonFragment">;
        } | null;
      }>;
      readonly assignmentRole?: {
        readonly name: string;
      } | null;
      readonly groupType: AllocationGroupType;
      readonly project?: {
        readonly name: string;
      } | null;
    }>;
    readonly intervalType: IntervalType;
    readonly intervals: ReadonlyArray<{
      readonly index: number;
      readonly " $fragmentSpreads": FragmentRefs<"IntervalHeaderComponent_IntervalFragment" | "allocationBarProvider_IntervalFragment">;
    }>;
  };
  readonly utilizationWithStandAndEndDate: {
    readonly " $fragmentSpreads": FragmentRefs<"personCard_ScenarioUtilizationFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"allocationBarProvider_ScenarioFragment" | "personCard_ScenarioFragment">;
  readonly " $fragmentType": "staffViewPart_ScenarioFragment";
};
export type staffViewPart_ScenarioFragment$key = {
  readonly " $data"?: staffViewPart_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"staffViewPart_ScenarioFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "utilizationWindow",
  "variableName": "utilizationWindow"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentRole",
  "kind": "LinkedField",
  "name": "assignmentRole",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = [
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "intervalType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personFiltersOpt"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectFiltersOpt"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scenarioId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showAll"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "utilizationWindow"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./staffViewPart_RefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "staffViewPart_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "intervalType",
          "variableName": "intervalType"
        },
        {
          "kind": "Variable",
          "name": "peopleOnAssignmentFilters",
          "variableName": "personFiltersOpt"
        },
        {
          "kind": "Variable",
          "name": "projectWithAssignmentsFilters",
          "variableName": "projectFiltersOpt"
        },
        {
          "kind": "Variable",
          "name": "showAll",
          "variableName": "showAll"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        (v0/*: any*/)
      ],
      "concreteType": "StaffView",
      "kind": "LinkedField",
      "name": "staffView",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "intervalType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntervalDescription",
          "kind": "LinkedField",
          "name": "intervals",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "IntervalHeaderComponent_IntervalFragment"
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "allocationBarProvider_IntervalFragment",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fallsIntoCustomUtilizationWindow",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "allocationGroups",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "groupType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonAllocation",
              "kind": "LinkedField",
              "name": "allocations",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v7/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "scenarioRef",
                          "variableName": "scenarioId"
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "sumOfProjectVolume",
                      "storageKey": null
                    },
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "scenarioId",
                          "variableName": "scenarioId"
                        }
                      ],
                      "kind": "FragmentSpread",
                      "name": "personCard_PersonFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Lane",
                  "kind": "LinkedField",
                  "name": "lanes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LaneAllocation",
                      "kind": "LinkedField",
                      "name": "allocations",
                      "plural": true,
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Assignment",
                          "kind": "LinkedField",
                          "name": "assignment",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Person",
                              "kind": "LinkedField",
                              "name": "person",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            },
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "allocationBarProvider_AllocationFragment",
                          "selections": [
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fromInterval",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fromIntervalPercentage",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "toInterval",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "toIntervalPercentage",
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Assignment",
                              "kind": "LinkedField",
                              "name": "assignment",
                              "plural": false,
                              "selections": [
                                (v4/*: any*/),
                                (v2/*: any*/),
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isExecutive",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AssignmentRole",
                                  "kind": "LinkedField",
                                  "name": "validAssignmentRoles",
                                  "plural": true,
                                  "selections": (v6/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "comment",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "importId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "weight",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v4/*: any*/),
                                    (v5/*: any*/),
                                    (v7/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Project",
                                  "kind": "LinkedField",
                                  "name": "project",
                                  "plural": false,
                                  "selections": [
                                    (v4/*: any*/),
                                    (v5/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "volume",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "ProjectStage",
                                      "kind": "LinkedField",
                                      "name": "stage",
                                      "plural": false,
                                      "selections": [
                                        (v4/*: any*/),
                                        (v5/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "color",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Milestone",
                                      "kind": "LinkedField",
                                      "name": "milestones",
                                      "plural": true,
                                      "selections": [
                                        (v5/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "date",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isGap",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lengthInDays",
                              "storageKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gapDays",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "ProjectGroup",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssignmentRole",
                  "kind": "LinkedField",
                  "name": "assignmentRole",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "AssignmentRoleGroup",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "ScenarioUtilization",
      "kind": "LinkedField",
      "name": "utilizationWithStandAndEndDate",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "personCard_ScenarioUtilizationFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personCard_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "allocationBarProvider_ScenarioFragment"
    },
    (v4/*: any*/)
  ],
  "type": "Scenario",
  "abstractKey": null
};
})();

(node as any).hash = "effb295e64038f2807b9c2590a54f67e";

export default node;
