/**
 * @generated SignedSource<<83d19e54248885177967e9f3424cfbff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsGridPart_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"loadPursuitProjectsFromRandDwhButton_ScenarioFragment" | "projectViewFiltersPart_ScenarioFragment" | "projectsGridPartContent_ScenarioFragment" | "syncWithRandPreconDwhButton_ScenarioFragment" | "updateAssignmentsFromDynamicsButton_ScenarioFragment">;
  readonly " $fragmentType": "ProjectsGridPart_ScenarioFragment";
};
export type ProjectsGridPart_ScenarioFragment$key = {
  readonly " $data"?: ProjectsGridPart_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsGridPart_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personOnAssignmentFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectFilters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsGridPart_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "personOnAssignmentFilters",
          "variableName": "personOnAssignmentFilters"
        },
        {
          "kind": "Variable",
          "name": "projectFilters",
          "variableName": "projectFilters"
        }
      ],
      "kind": "FragmentSpread",
      "name": "projectsGridPartContent_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "updateAssignmentsFromDynamicsButton_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectViewFiltersPart_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "syncWithRandPreconDwhButton_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "loadPursuitProjectsFromRandDwhButton_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "954185bb88b283bfb04334a72b20de7d";

export default node;
