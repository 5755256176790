import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	readInlineData,
	useLazyLoadQuery,
	useRefetchableFragment,
	useSubscribeToInvalidationState,
} from "react-relay";
import { PersonCard } from "@components/person-card";
import { selectStaffViewFilters, type StaffViewFilters } from "@redux/StaffViewSlice";
import { type allocationBarProvider_IntervalFragment$key } from "@relay/allocationBarProvider_IntervalFragment.graphql";
import { type UtilizationWindowInput } from "@relay/RosterList_StaffRefetch.graphql";
import { type staffViewPart_Query } from "@relay/staffViewPart_Query.graphql";
import {
	type staffViewPart_RefetchQuery,
	type staffViewPart_RefetchQuery$variables,
} from "@relay/staffViewPart_RefetchQuery.graphql";
import { type staffViewPart_ScenarioFragment$key } from "@relay/staffViewPart_ScenarioFragment.graphql";
import {
	AllocationBarProvider,
	type AllocationBarProviderRef,
	INTERVAL_FRAGMENT,
} from "@screens/staff-view/parts/allocation-bar/context";
import { AllocationBarComponent } from "@screens/staff-view/parts/AllocationBarComponent";
import { IntervalHeaderComponent } from "@screens/staff-view/parts/IntervalHeaderComponent";
import {
	SCENARIO_FRAGMENT,
	STAFF_VIEW_QUERY,
} from "@screens/staff-view/parts/staff-view-part/staff-view-part.graphql";
import {
	calculateLaneHeight,
	checkIfAnAllocationChainsWithTheNextOne,
} from "@screens/staff-view/parts/staff-view-part/staff-view-part.utils";
import {
	COLUMN_WIDTH,
	HEADER_MARGIN,
	HEADER_SIZE,
	LANE_HEIGHT,
	MARGIN_BETWEEN_LANES,
	MARGIN_BETWEEN_PEOPLE,
	SIDEBAR_SIZE,
	SUBHEADER_SIZE,
} from "@screens/staff-view/parts/staff-view.utils";

interface OwnProps {
	scenarioId: string;
}

export const StaffViewPart = ({ scenarioId }: OwnProps) => {
	const filters = useSelector(selectStaffViewFilters);

	const query = useLazyLoadQuery<staffViewPart_Query>(
		STAFF_VIEW_QUERY,
		{
			id: scenarioId,
			// ...filters,
			projectFiltersOpt: {
				divisions: filters.filterByDivisions,
				stages: filters.filterByStages,
				regions: filters.filterByRegions,
				executives: filters.filterByExecutives?.length
					? filters.filterByExecutives
					: undefined,
			},
			personFiltersOpt: {
				executives: filters.filterByExecutives?.length
					? filters.filterByExecutives
					: undefined,
				gapDays: {
					from: filters.filterByGapDaysMinimum,
					to: filters.filterByGapDaysMaximum,
				},
				salary: { from: filters.filterBySalaryMinimum, to: filters.filterBySalaryMaximum },
				assignmentInDateRange: {
					from:
						filters.filterByAssignmentDateMinimum ??
						filters.filterByAllocatedDateMinimum,
					to:
						filters.filterByAssignmentDateMaximum ??
						filters.filterByAllocatedDateMaximum,
				},
				ids: filters.filterByStaff,
				name: filters.filterByPersonName,
				currentlyAssignedAssignmentRoles: filters.filterByCurrentlyAssignedAssignmentRoles,
				jobTitles: filters.filterByAssignmentRoles,
				utilizationStatuses: filters.filterByUtilizationStatus,
				utilizationWindow:
					filters.startDate && filters.endDate
						? { utilizationStart: filters.startDate, utilizationEnd: filters.endDate }
						: undefined,
			},
			intervalType: filters.intervalType || "Weeks",
			utilizationWindow:
				filters.startDate && filters.endDate
					? { utilizationStart: filters.startDate, utilizationEnd: filters.endDate }
					: undefined,
		},
		{ fetchPolicy: "network-only" },
	);

	const [scenario, refetch] = useRefetchableFragment<
		staffViewPart_RefetchQuery,
		staffViewPart_ScenarioFragment$key
	>(SCENARIO_FRAGMENT, query.node!);

	const [initialLoad, setInitialLoadComplete] = useState(true);

	const debouncedRefetch = (filters: StaffViewFilters) => {
		const _filters: Partial<staffViewPart_RefetchQuery$variables & StaffViewFilters> = {
			...filters,
			projectFiltersOpt: {
				divisions: filters.filterByDivisions,
				stages: filters.filterByStages,
				regions: filters.filterByRegions,
				executives: filters.filterByExecutives?.length
					? filters.filterByExecutives
					: undefined,
			},
			personFiltersOpt: {
				executives: filters.filterByExecutives?.length
					? filters.filterByExecutives
					: undefined,
				gapDays: {
					from: filters.filterByGapDaysMinimum,
					to: filters.filterByGapDaysMaximum,
				},
				salary: { from: filters.filterBySalaryMinimum, to: filters.filterBySalaryMaximum },
				assignmentInDateRange: {
					from:
						filters.filterByAssignmentDateMinimum ??
						filters.filterByAllocatedDateMinimum,
					to:
						filters.filterByAssignmentDateMaximum ??
						filters.filterByAllocatedDateMaximum,
				},
				ids: filters.filterByStaff,
				name: filters.filterByPersonName,
				currentlyAssignedAssignmentRoles: filters.filterByCurrentlyAssignedAssignmentRoles,
				jobTitles: filters.filterByAssignmentRoles,
				utilizationStatuses: filters.filterByUtilizationStatus,
			},
			intervalType: filters.intervalType || "Weeks",
		};

		const utilizationWindow: UtilizationWindowInput | undefined =
			filters.startDate && filters.endDate
				? { utilizationStart: filters.startDate!, utilizationEnd: filters.endDate! }
				: undefined;
		if (utilizationWindow) {
			_filters.utilizationWindow = utilizationWindow;
			if (!_filters.personFiltersOpt) _filters.personFiltersOpt = {};
			_filters.personFiltersOpt.utilizationWindow = utilizationWindow;
		}
		refetch({ ..._filters }, { fetchPolicy: "network-only" });
	};

	const assigmentIds = scenario.staffView.allocationGroups
		.flatMap((e) => e.allocations)
		.flatMap((e) => e.lanes)
		.flatMap((e) => e.allocations)
		.flatMap((e) => e.assignment?.id) as string[];

	useSubscribeToInvalidationState([...assigmentIds], () => {
		debouncedEventHandler(filters);
	});

	const allocationBarProviderRef = useRef<AllocationBarProviderRef>(null);
	const debouncedEventHandler = useMemo(
		() => {
			const cb = (filters: StaffViewFilters) => {
				debouncedRefetch(filters);
				allocationBarProviderRef.current?.resetPosition();
			};
			return debounce(cb, 1000);
		},
		// eslint-disable-next-line
		[],
	);

	useEffect(() => {
		if (initialLoad) {
			setInitialLoadComplete(false);
		} else if (filters) {
			debouncedEventHandler(filters);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	let userOffset = HEADER_SIZE + HEADER_MARGIN;

	const showSubheaders =
		filters.sort === "PositionAsc" ||
		filters.sort === "PositionDesc" ||
		filters.sort === "ProjectAsc" ||
		filters.sort === "ProjectDesc";

	let cumulativeSubheaderOffset = 0;

	const subheadingsOffset = scenario.staffView.allocationGroups.length * SUBHEADER_SIZE;

	const intervalDescriptions = scenario.staffView.intervals.map((e) =>
		readInlineData<allocationBarProvider_IntervalFragment$key>(INTERVAL_FRAGMENT, e),
	);

	return scenario.staffView.allocationGroups.length === 0 ? (
		<div>
			There are no assignments that match the filter criteria. Add assignments to your
			scenario or change the filters.
		</div>
	) : (
		<div className="h-full overflow-scroll relative">
			<div className="sticky top-0 w-max z-5 bg-white flex">
				<div
					className="flex align-items-start"
					style={{ width: SIDEBAR_SIZE, paddingRight: 20 }}
				></div>
				<div className="flex">
					{scenario.staffView.intervals.map((interval) => {
						return (
							<IntervalHeaderComponent
								key={"header-" + interval.index}
								intervalFragmentRef={interval}
							/>
						);
					})}
				</div>
			</div>

			<div></div>

			<div
				className="sticky left-0 z-4 bg-white"
				style={{
					width: SIDEBAR_SIZE,
					paddingRight: 20,
					paddingTop: HEADER_MARGIN,
				}}
			>
				{scenario.staffView.allocationGroups.map((allocationGroup) => {
					return allocationGroup.allocations.map((personAllocation, index) => {
						const height = calculateLaneHeight(personAllocation.lanes.length);
						const person = personAllocation.person;

						const showSubheader = showSubheaders && index === 0;

						const heightWithSubheader = height + (showSubheader ? SUBHEADER_SIZE : 0);

						return person ? (
							<div
								className="flex flex-column"
								key={"user-" + person.id}
								style={{
									minHeight: heightWithSubheader,
									maxHeight: heightWithSubheader,
									height: heightWithSubheader,
									marginBottom: MARGIN_BETWEEN_PEOPLE,
								}}
							>
								{showSubheader && (
									<h3
										className="mt-0"
										style={{
											fontSize: 18,
											marginBottom: 5,
										}}
									>
										{allocationGroup.assignmentRole?.name ??
											allocationGroup.project?.name}
									</h3>
								)}
								<PersonCard
									className="flex-grow-1 m-0 "
									style={{ height: "100%" }}
									scenarioFragmentRef={scenario}
									personFragmentRef={person}
									gapDaysOverride={personAllocation.gapDays}
									hideGapDays={allocationGroup.groupType === "project"}
									hideTotalVolume={false}
									scenarioUtilizationRef={scenario.utilizationWithStandAndEndDate}
								/>
							</div>
						) : null;
					});
				})}
			</div>

			<div
				className="absolute top-0 left-0 z-0"
				style={{
					paddingLeft: SIDEBAR_SIZE + 25,
				}}
			>
				<div className="relative bg-white">
					{scenario.staffView.allocationGroups.map((allocationGroup) => {
						return allocationGroup.allocations.map((personAllocation, index) => {
							const showSubheader = showSubheaders && index === 0;
							const subheaderOffset = showSubheader ? SUBHEADER_SIZE : 0;

							cumulativeSubheaderOffset = cumulativeSubheaderOffset + subheaderOffset;

							const lanes = personAllocation.lanes.flatMap((lane, laneIndex) => {
								const laneTopOffset =
									userOffset +
									cumulativeSubheaderOffset +
									laneIndex * (LANE_HEIGHT + MARGIN_BETWEEN_LANES);

								return lane.allocations.map((allocation, allocationIndex) => {
									const isGapAndHideIt =
										allocationGroup.groupType === "project" &&
										allocation.assignment?.id === undefined;

									return isGapAndHideIt ? null : (
										<AllocationBarProvider
											key={
												personAllocation.person!.id +
												"-lane-" +
												laneIndex +
												"-allocation-" +
												allocationIndex +
												"-provider"
											}
											ref={allocationBarProviderRef}
											laneAllocationFragmentRef={allocation}
											laneAllocationIds={lane.allocations.map((a) => a.id)}
											intervalDescriptions={intervalDescriptions}
											scenarioFragmentRef={scenario}
											doesChainWithTheNextBar={checkIfAnAllocationChainsWithTheNextOne(
												allocation,
												lane.allocations[allocationIndex + 1],
											)}
										>
											<AllocationBarComponent
												key={
													personAllocation.person!.id +
													"-lane-" +
													laneIndex +
													"-allocation-" +
													allocationIndex
												}
												topOffset={laneTopOffset}
											/>
										</AllocationBarProvider>
									);
								});
							});

							const allLanesHeight = calculateLaneHeight(
								personAllocation.lanes.length,
							);

							userOffset = userOffset + allLanesHeight + MARGIN_BETWEEN_PEOPLE;

							return lanes;
						});
					})}

					{intervalDescriptions.map((interval, index) => {
						return (
							<div
								className="z-5 absolute top-0"
								key={"separator-" + index}
								style={{
									left: index * COLUMN_WIDTH,
									height: userOffset + subheadingsOffset,
									borderLeft: `1px solid #d2d7e1`,
									width: 1,
									...(interval?.fallsIntoCustomUtilizationWindow && {
										backgroundColor: "yellow",
										opacity: 0.2,
										width: COLUMN_WIDTH,
										borderLeft: `1px solid #d2d7e1`,
									}),
								}}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};
