/**
 * @generated SignedSource<<abcf1caa4059a7477e89a611a2c21a58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type rosterPart_ScenarioFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RosterList_ScenarioFragment" | "rosterListActiveFilters_ScenarioFragment">;
  readonly " $fragmentType": "rosterPart_ScenarioFragment";
};
export type rosterPart_ScenarioFragment$key = {
  readonly " $data"?: rosterPart_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rosterPart_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rosterPart_ScenarioFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RosterList_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "rosterListActiveFilters_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "209dca2aa49b6c8eff8271db0b6575d6";

export default node;
