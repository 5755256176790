/**
 * @generated SignedSource<<f7cf75270488d8fbf9190aa018437bab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadDriveTimesButton_ProjectQuery$variables = {
  projectId: string;
};
export type LoadDriveTimesButton_ProjectQuery$data = {
  readonly node: {
    readonly address?: {
      readonly latitude: number | null;
      readonly longitude: number | null;
    } | null;
    readonly id?: string;
  } | null;
};
export type LoadDriveTimesButton_ProjectQuery = {
  response: LoadDriveTimesButton_ProjectQuery$data;
  variables: LoadDriveTimesButton_ProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadDriveTimesButton_ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "Project",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadDriveTimesButton_ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Project",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c54f81081a187c3fedc8dd9a9d3e1fa5",
    "id": null,
    "metadata": {},
    "name": "LoadDriveTimesButton_ProjectQuery",
    "operationKind": "query",
    "text": "query LoadDriveTimesButton_ProjectQuery(\n  $projectId: ID!\n) {\n  node(id: $projectId) {\n    __typename\n    ... on Project {\n      id\n      address {\n        latitude\n        longitude\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0fb3ceaa5478855f2557f24f5bece879";

export default node;
