/**
 * @generated SignedSource<<76093f9aca39632a5bf1264d4f67f034>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectMapPart_ProjectInScenarioFragment$data = {
  readonly project: {
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentsInProjectList_ProjectFragment" | "ProjectMap_ProjectFragment">;
  readonly " $fragmentType": "ProjectMapPart_ProjectInScenarioFragment";
};
export type ProjectMapPart_ProjectInScenarioFragment$key = {
  readonly " $data"?: ProjectMapPart_ProjectInScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectMapPart_ProjectInScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectMapPart_ProjectInScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignmentsInProjectList_ProjectFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectMap_ProjectFragment"
    }
  ],
  "type": "ProjectInScenario",
  "abstractKey": null
};

(node as any).hash = "9f3dd20c661a828200a50ae4ddfcb46e";

export default node;
