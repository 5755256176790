/**
 * @generated SignedSource<<71abfcbdcf3882b2efe4faf719a2f707>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectsGridPartContent_ProjectInScenarioInlineFragment$data = {
  readonly assignments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly isExecutive: boolean;
        readonly person: {
          readonly assignmentRole: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly id: string;
        } | null;
        readonly project: {
          readonly id: string;
        };
        readonly validAssignmentRoles: ReadonlyArray<{
          readonly name: string;
        }>;
      };
    } | null> | null;
  };
  readonly id: string;
  readonly project: {
    readonly division: {
      readonly id: string;
    } | null;
    readonly endDate: any | null;
    readonly name: string;
    readonly region: {
      readonly id: string;
    } | null;
    readonly stage: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly startDate: any | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"projectCard_ProjectFragment">;
  readonly " $fragmentType": "projectsGridPartContent_ProjectInScenarioInlineFragment";
};
export type projectsGridPartContent_ProjectInScenarioInlineFragment$key = {
  readonly " $data"?: projectsGridPartContent_ProjectInScenarioInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectsGridPartContent_ProjectInScenarioInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "projectsGridPartContent_ProjectInScenarioInlineFragment"
};

(node as any).hash = "2ca87fd75fd95e2dc0750f00fab8b5fa";

export default node;
