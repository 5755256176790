/**
 * @generated SignedSource<<fb29dd8a91512fb45f5bb6124bb96d4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectsGridPartContent_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"addProjectToScenarioCard_ScenarioFragment" | "projectCard_ScenarioFragment" | "projectsGridPartContent_ScenarioRefetchableFragment">;
  readonly " $fragmentType": "projectsGridPartContent_ScenarioFragment";
};
export type projectsGridPartContent_ScenarioFragment$key = {
  readonly " $data"?: projectsGridPartContent_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectsGridPartContent_ScenarioFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "personOnAssignmentFilters",
    "variableName": "personOnAssignmentFilters"
  },
  {
    "kind": "Variable",
    "name": "projectFilters",
    "variableName": "projectFilters"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personOnAssignmentFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectFilters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectsGridPartContent_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "projectsGridPartContent_ScenarioRefetchableFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "addProjectToScenarioCard_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectCard_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};
})();

(node as any).hash = "4a751c1d96fb1c866d60023ce6370c81";

export default node;
