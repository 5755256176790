/**
 * @generated SignedSource<<2d997c1b1b92870363f8c1e737929423>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type addProjectToScenarioCard_ScenarioFragment$data = {
  readonly isMasterPlan: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"addProjectToScenarioModal_ScenarioFragment">;
  readonly " $fragmentType": "addProjectToScenarioCard_ScenarioFragment";
};
export type addProjectToScenarioCard_ScenarioFragment$key = {
  readonly " $data"?: addProjectToScenarioCard_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"addProjectToScenarioCard_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personOnAssignmentFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectFilters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "addProjectToScenarioCard_ScenarioFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "personOnAssignmentFilters",
          "variableName": "personOnAssignmentFilters"
        },
        {
          "kind": "Variable",
          "name": "projectFilters",
          "variableName": "projectFilters"
        }
      ],
      "kind": "FragmentSpread",
      "name": "addProjectToScenarioModal_ScenarioFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMasterPlan",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "922b4c9d42a9d97ae3dbb0d42d48be56";

export default node;
